
import { useDispatch } from 'react-redux';
import { setTripType, flexiDateValue, setFlyingFrom, setFlyingFromCountry, setFlyingTo, setDepartDate, setReturnDate, setPassengerAdult,
    setPassengerChildren, setPassengerInfant, setCabin, setAirContract, setQualifiers, setAirlines, setMaxConnections, setChangesAllowed,
    setAlliance, setNearbyAirport, setAtleastFreeBaggage, setAutoCompleteAirLines, setUUID, setCarrierOptions, setInputFieldCount,
    setOpenJawDate_B, setOpenJawFlyingFrom_B, setOpenJawFlyingTo_B, setMultiCityFlyingFrom_4, setMultiCityFlyingTo_4, setMultiCityFlyingFrom_0,
    setMultiCityFlyingTo_0, setMultiCityFlyingFrom_1, setMultiCityFlyingTo_1, setMultiCityFlyingFrom_2, setMultiCityFlyingTo_2, setMultiCityFlyingFrom_3,
    setMultiCityFlyingTo_3, setMultiCityDate_4, setMultiCityDate_0, setMultiCityDate_1, setMultiCityDate_2, setMultiCityDate_3, removeinput,
    setRequestOptions, setItinenaryIndex, setBookingItinenary, setBookingHeader, setBookingFareId, setFlyingFromCity, setFlyingToCity, setFlyingFromValidation,
    setFlyingToValidation, setDepartureDateValidation, setReturnDateValidation, setIsErrorIncrement, setIsErrorDecrement, setIsErrorRoundTrip,
    setIsErrorOneWayTrip, setIsErrorOpenJawTrip, setIsErrorMultiCityTrip, setAdultMarkupValue, setChildMarkupValue, setInfantMarkupValue, setOpenJawFlyingFromCity_B,
    setOpenJawFlyingToCity_B, setMultiCityFlyingFromCity_0, setMultiCityFlyingToCity_0, setMultiCityFlyingFromCity_1, setMultiCityFlyingToCity_1,
    setMultiCityFlyingFromCity_2, setMultiCityFlyingToCity_2, setMultiCityFlyingFromCity_3, setMultiCityFlyingToCity_3, setMultiCityFlyingFromCity_4, 
    setMultiCityFlyingToCity_4, setModifySearchValue, setSegmentArrayLength, setProvideId, setTimeCalculator, setPromotionData, setGroupItinenaryTimeCalculator,
    setApiRenderFlag, setTripTypeFromApi, setDepartFromCity, setDepartFromCode, setArriveFromCity, setArriveFromCode, setFlyingFromModifySearch,
    setFlyingToModifySearch, setDepartDateModifySearch, setReturnDateModifySearch, setFlyingFromModifySearchCode, setFlyingToModifySearchCode, setShowAgencyInfo,
    setAllianceOption, setOpenJawFlyingFromCountry_B, setMultiCityFlyingFromCountry_4, setMultiCityFlyingFromCountry_3, setMultiCityFlyingFromCountry_2,
    setMultiCityFlyingFromCountry_1, setMultiCityFlyingFromCountry_0, setPNRRemarkCityFraudCheck, setSpecificAirlinesBlank, setAgencyAgentFieldFlag, setCacheExpiredFlag,
    setAutoCompleteReturnTo, setAutoCompleteReturnFrom, setAutoCompleteFlyFrom_B, setAutoCompleteFlyTo_B, setAutoCompleteFlyFrom_0, setAutoCompleteFlyTo_0,
    setAutoCompleteFlyFrom_1, setAutoCompleteFlyTo_1, setAutoCompleteFlyFrom_2, setAutoCompleteFlyTo_2, setAutoCompleteFlyFrom_3, setAutoCompleteFlyTo_3, 
    setAutoCompleteFlyFrom_4, setAutoCompleteFlyTo_4, setCacheExpireDialogFlag, setAutoCompleteQuoteFrom, setAutoCompleteQuoteTo, setAgencyList, setAgentList, setAgencyField,
    setAgentField,setFlyingFromTextReturn,
    setFlyingFromTextOJ,
    setFlyingFromTextMulti0,
    setFlyingFromTextMulti1,
    setFlyingFromTextMulti2,
    setFlyingFromTextMulti3,
    setFlyingFromTextMulti4,
    setFlyingToTextReturn,
    setFlyingToTextOJ,
    setFlyingToTextMulti0,
    setFlyingToTextMulti1,
    setFlyingToTextMulti2,
    setFlyingToTextMulti3,
    setFlyingToTextMulti4,
    setDepartTime,
    setOpenJawTimeB,
    setMultiCityTime4,
    setMultiCityTime3,
    setMultiCityTime2,
    setMultiCityTime1,
    setMultiCityTime0,
    setReturnTime,
    setCityFraudCheck,
    setCityFraudCheck1,
    setAutoCompleteFlyFromM,
    setAutoCompleteFlyToM,
    setMultiCityTimeM,
    setMultiCityFlyingFromM,
    setMultiCityFlyingToM,
    setMultiCityDateM,
    setMultiCityFlyingFromCityM,
    setMultiCityFlyingToCityM,
    setMultiCityFlyingFromCountryM,
    setClearFormFlag,
    defaultBrandData,
    setInitialSearchForMulticity,
    setPaxContract,
    setFareRulesError,
    setFlightType,
    setUrlUsd,
    setFromFlexiDateSearch,
    setDepartureDateModifySearchFlexi,
    setReturnDateModifySearchFlexi
  } from '../actionCreators/searchForm';

export function ResetSearchForm() {
    const dispatch = useDispatch();

    dispatch(setTripType("Return"));
    dispatch(setInitialSearchForMulticity(0));
    dispatch(setClearFormFlag(false))
    dispatch(setMultiCityFlyingFromCountryM());
    dispatch(setMultiCityFlyingToCityM(""));
    dispatch(setMultiCityFlyingFromCityM(""));
    dispatch(setMultiCityDateM(""));
    dispatch(setMultiCityFlyingToM (""));
    dispatch(setMultiCityFlyingFromM(""));
    dispatch(setMultiCityTimeM (""));
    dispatch(setAutoCompleteFlyFromM([]));
    dispatch(setAutoCompleteFlyToM([]));
    dispatch(setCityFraudCheck(false))
    dispatch(setCityFraudCheck1(false))
    dispatch(setDepartTime(""));
    dispatch(setOpenJawTimeB(""));
    dispatch(setMultiCityTime4(""));
    dispatch(setMultiCityTime3(""));
    dispatch(setMultiCityTime2(""));
    dispatch(setMultiCityTime1(""));
    dispatch(setMultiCityTime0(""));
    dispatch(setReturnTime(""));
    dispatch(setFlyingFromTextReturn(""));
    dispatch(setFlyingFromTextOJ(""));
    dispatch(setFlyingFromTextMulti0(""));
    dispatch(setFlyingFromTextMulti1(""));
    dispatch(setFlyingFromTextMulti2(""));
    dispatch(setFlyingFromTextMulti3(""));
    dispatch(setFlyingFromTextMulti4(""));
    dispatch(setFlyingToTextReturn(""));
    dispatch(setFlyingToTextOJ(""));
    dispatch(setFlyingToTextMulti1(""));
    dispatch(setFlyingToTextMulti0(""));
    dispatch(setFlyingToTextMulti2(""));
    dispatch(setFlyingToTextMulti3(""));
    dispatch(setFlyingToTextMulti4(""))
    dispatch(setAgentField(""));
    dispatch(setAgencyField(""));
    dispatch(setAgencyList([]));
    dispatch(setAgentList([]));
    dispatch(setAllianceOption(""));
    dispatch(setAutoCompleteQuoteFrom([]));
    dispatch(setAutoCompleteQuoteTo([]));
    dispatch(setAutoCompleteReturnTo([]));
    dispatch(setAutoCompleteReturnFrom([]));
    dispatch(setAutoCompleteFlyFrom_B([]));
    dispatch(setAutoCompleteFlyTo_B([]));
    dispatch(setAutoCompleteFlyFrom_0([]));
    dispatch(setAutoCompleteFlyTo_0([]));
    dispatch(setAutoCompleteFlyFrom_1([]));
    dispatch(setAutoCompleteFlyTo_1([]));
    dispatch(setAutoCompleteFlyFrom_2([]));
    dispatch(setAutoCompleteFlyTo_2([]));
    dispatch(setAutoCompleteFlyFrom_3([]));
    dispatch(setAutoCompleteFlyTo_3([]));
    dispatch(setAutoCompleteFlyFrom_4([]));
    dispatch(setAutoCompleteFlyTo_4([]));
    dispatch(setCacheExpiredFlag(false));
    dispatch(setCacheExpireDialogFlag(false));
    dispatch(setAgencyAgentFieldFlag(false));
    dispatch(setSpecificAirlinesBlank(false));
    dispatch(setPNRRemarkCityFraudCheck(false));
    dispatch(setMultiCityFlyingFromCountry_4(""));
    dispatch(setMultiCityFlyingFromCountry_0(""))
    dispatch(setMultiCityFlyingFromCountry_1(""));
    dispatch(setMultiCityFlyingFromCountry_2(""));
    dispatch(setMultiCityFlyingFromCountry_3(""));
    dispatch(setOpenJawFlyingFromCountry_B(""));
    dispatch(setShowAgencyInfo(false));
    dispatch(setFlyingToModifySearchCode(""));
    dispatch(setFlyingFromModifySearchCode(""));
    dispatch(setReturnDateModifySearch(""));
    dispatch(setDepartDateModifySearch(""));
    dispatch(setFlyingToModifySearch(""));
    dispatch(setFlyingFromModifySearch(""));
    dispatch(setArriveFromCode(""));
    dispatch(setArriveFromCity(""))
    dispatch(setDepartFromCode(""));
    dispatch(setDepartFromCity(""));
    dispatch(setTripTypeFromApi(""));
    dispatch(setApiRenderFlag(true));
    dispatch(setGroupItinenaryTimeCalculator(0));
    dispatch(setPromotionData(""));
    dispatch(setTimeCalculator(0));
    dispatch(setProvideId(""));
    dispatch(setSegmentArrayLength(""));
    dispatch(setModifySearchValue(false));
    dispatch(setMultiCityFlyingFromCity_3(""));
    dispatch(setMultiCityFlyingToCity_4(""));
    dispatch(setMultiCityFlyingToCity_3(""));
    dispatch(setMultiCityFlyingFromCity_4(""));
    dispatch(setMultiCityFlyingFromCity_2(""));
    dispatch(setMultiCityFlyingToCity_2(""));
    dispatch(setMultiCityFlyingFromCity_1(""));
    dispatch(setMultiCityFlyingToCity_1(""));
    dispatch(setMultiCityFlyingFromCity_0(""));
    dispatch(setMultiCityFlyingToCity_0(""));
    dispatch(flexiDateValue(false));
    dispatch(setFlyingFrom(""));
    dispatch(setFlyingFromCountry(""));
    dispatch(setFlyingTo(""));
    dispatch(setDepartDate(""));
    dispatch(setReturnDate(""));
    dispatch(setPassengerAdult(1));
    dispatch(setPassengerChildren(0));
    dispatch(setPassengerInfant(0));
    dispatch(setCabin("Economy"));
    dispatch(setAirContract(['Air']));
    dispatch(setQualifiers([]));
    dispatch(setAirlines([]));
    dispatch(setMaxConnections(2));
    dispatch(setChangesAllowed(""));
    dispatch(setAlliance(""));
    dispatch(setNearbyAirport(false));
    dispatch(setAtleastFreeBaggage(false));
    dispatch(setAutoCompleteAirLines([]));
    dispatch(setUUID(""));
    dispatch(setCarrierOptions([]));
    dispatch(setInputFieldCount(0));
    dispatch(setOpenJawDate_B(""));
    dispatch(setOpenJawFlyingFrom_B(""));
    dispatch(setOpenJawFlyingTo_B(""));
    dispatch(setMultiCityFlyingFrom_4(""));
    dispatch(setMultiCityFlyingTo_4(""));
    dispatch(setMultiCityFlyingFrom_0(""));
    dispatch(setMultiCityFlyingTo_0(""));
    dispatch(setMultiCityFlyingFrom_1(""));
    dispatch(setMultiCityFlyingTo_1(""));
    dispatch(setMultiCityFlyingFrom_2(""));
    dispatch(setMultiCityFlyingTo_2(""));
    dispatch(setMultiCityFlyingFrom_3(""));
    dispatch(setMultiCityFlyingTo_3(""));
    dispatch(setMultiCityDate_4(""));
    dispatch(setMultiCityDate_0(""));
    dispatch(setMultiCityDate_1(""));
    dispatch(setMultiCityDate_2(""));
    dispatch(setMultiCityDate_3(""));
    // dispatch(removeinput([" "]));
    dispatch(setRequestOptions({}));
    dispatch(setItinenaryIndex(""));
    dispatch(setBookingItinenary(""));
    dispatch(setBookingHeader(""));
    dispatch(setBookingFareId(""));
    dispatch(setFlyingFromCity(""));
    dispatch(setFlyingToCity(""));
    dispatch(setFlyingFromValidation(""));
    dispatch(setFlyingToValidation(""));
    dispatch(setDepartureDateValidation(""));
    dispatch(setReturnDateValidation(""));
    dispatch(setIsErrorIncrement(0));
    dispatch(setIsErrorDecrement(0));
    dispatch(setIsErrorRoundTrip(false));
    dispatch(setIsErrorOneWayTrip(false));
    dispatch(setIsErrorOpenJawTrip(false));
    dispatch(setIsErrorMultiCityTrip(false));
    dispatch(setAdultMarkupValue(0));
    dispatch(setChildMarkupValue(0));
    dispatch(setInfantMarkupValue(0));
    dispatch(setOpenJawFlyingFromCity_B(""));
    dispatch(setOpenJawFlyingToCity_B(""));
    dispatch(defaultBrandData({}));
    dispatch(setPaxContract([]));
    dispatch(setFareRulesError(false));
    dispatch(setFlightType("CAD"));
    dispatch(setUrlUsd(`/?user=internal`));
    dispatch(setFromFlexiDateSearch(false));
    dispatch(setDepartureDateModifySearchFlexi(""));
    dispatch(setReturnDateModifySearchFlexi(""));
}