//import {getApiResponse} from "./../../utils/utility/getCommonApiResponse"
import { setPaymentRules ,setMarkupApplied, setPaymentRulesForCheque, setItenaryData, setItinaryFaresType, setInitialPaymentRules, setInitialPaymentRulesChq} from "../../appStore/actionCreators/flightBooking"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { env } from '../../../src/appConfig'
import getApiResponse from '../../services/getApiResponse'
toast.configure();

export const getPaymentRules = (requestbody,fopType,flowFrom) => {
    console.log("why")
    return async (dispatch, getState) => {
        const tokenData = getState().initToken;
        const quoteDetails = getState().savedQuotes.repriceQuote;
        const token = tokenData && tokenData.token;
        const flightType = getState().searchForm.flightType;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
        
       
        let tokenCurrent = quoteDetails && quoteDetails.status === "QuoteRepriced" ? quoteDetails.token : token;

        let urlNew;

        if(tokenCurrent === null){
            urlNew = env.REACT_APP_API_BASE_URL + `/applyPaymentRules`
        }else{
            urlNew = env.REACT_APP_API_BASE_URL + `/applyPaymentRules?token=${tokenCurrent}`
        }

        const corrId = getState().searchForm.uuid;

        const config = {
            method: "POST",
            url: urlNew,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "cache-control": "no-cache",
                correlationId: corrId,
                flightType:flightType,
                bookingChannel:bookingChannel
            },
            data: requestbody              
                    
                
        }
       
        const paymetRules = await getApiResponse(config);
        if(!paymetRules){
            toast.error("Technical error occured", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: true,
              });
              return null;
        }
        if(paymetRules && paymetRules.error){
            dispatch(setMarkupApplied(false))
            toast.error(paymetRules.error.message, { position: toast.POSITION.TOP_CENTER, autoClose: true });
            return paymetRules
        } else{
            if(fopType === "CreditCard" && flowFrom !== "fromMarkup"){
                dispatch(setPaymentRules(paymetRules)) 
                dispatch(setInitialPaymentRules(paymetRules))              
            }else if(fopType === "Cheque" && flowFrom !== "fromMarkup"){             
                dispatch(setPaymentRulesForCheque(paymetRules))
                dispatch(setInitialPaymentRulesChq(paymetRules))
            }else{
                if(flowFrom !== "fromMarkup"){
                dispatch(setPaymentRules(paymetRules))
                dispatch(setInitialPaymentRules(paymetRules))
                }
            }
            dispatch(setMarkupApplied(true))
            dispatch(setItenaryData(paymetRules.itineraryFare))
            dispatch(setItinaryFaresType(paymetRules && paymetRules.itineraryFare && paymetRules.itineraryFare.type))
            return paymetRules
        }
        

    }
}