import { toast } from "react-toastify";
function ToastMessages(msg){
toast.error(msg,{
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    })
// toast.warn('Sesame snaps donut gingerbread Etiam quis diam quis lectus.', {
//     position: "top-center",
//     autoClose: false,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     });
}
export default ToastMessages;