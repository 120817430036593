import { setSegment,setItenaryData, setItinaryFaresType,setSuccessPrice} from "../../appStore/actionCreators/flightBooking"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { env } from '../../../src/appConfig'
import getApiResponse from "../../services/getApiResponse";

export const updatePnr = (payload) => {
    return async (dispatch, getState) => {
        dispatch(setSuccessPrice(false))
        const tokenData = getState().initToken;
        ////const token = tokenData && tokenData.token && tokenData.token.token;
        const corrId = getState().searchForm.uuid;
        const flightType = getState().searchForm.flightType;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
        const agentProfile = getState().agentProfile;
        const { agencyDetails, agentDetails, internalUserP6} = agentProfile
        const travelAgent = {
            firstName: isInternalUserAutheticated ? agentDetails?.firstName : agencyDetails?.firstName,
            lastName: isInternalUserAutheticated ? agentDetails?.lastName : agencyDetails?.lastName,
            userName: isInternalUserAutheticated ? agentDetails?.username : agencyDetails?.username,
            phone: {
                number: agentDetails?.contactNumber,
                type: "Unknown"
            },
            email: isInternalUserAutheticated ? agentDetails?.email : agencyDetails?.email,
            agentNumericCode: agentProfile?.agentOldUserId
        };

        const agencyData =
        {
            code: agencyDetails?.adAgencyCode,
            name: agencyDetails?.adTravelAgencyName,
            networkId: agencyDetails?.adNetworkId,
            address: {
                line1: agencyDetails?.adAddressLine1,
                line2: agencyDetails?.adAddressLine2,
                city: {
                    name: agencyDetails?.adCity,
                },
                state: {
                    code: agencyDetails?.provinceOrState
                },
                country: {
                    code: agencyDetails?.country
                },
                postalCode: agencyDetails?.adPostalCode,
                addressType: "Unspecified"
            },
            phone: {
                number: agencyDetails?.adNetworkId,
                type: "Unknown"
            },
            scheduleChangeEmail: agencyDetails?.ciEmailAirSchedule
        };

        payload.travelAgent = travelAgent;
        payload.agency = agencyData;

        //Axios payload

        const config = {
            method: "POST",
            url : env.REACT_APP_API_BASE_URL + `/booking/process/updatePNR`,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "cache-control": "no-cache",
                correlationId: corrId,
                flightType:flightType,
                bookingChannel:bookingChannel                
            },
            data:
                payload  
                    
        }


        const result = await getApiResponse(config);

        if (result) {
           return result

        }

    }
}