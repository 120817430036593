import { SET_LANGUAGE_SELECTED } from '../../storeConstants';

const initialState = {
  lang: "en"
};

const Language_Reducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE_SELECTED:
      return {
        ...state,
        lang: action.val,
      };
    default:
      return state;
  }
};

export default Language_Reducers;
