import { setAgentList } from "../actionCreators/searchForm";
import { env } from '../../appConfig'
import getApiResponse from "../../services/getApiResponse";

export const getAgent = (value) => {
        return async (dispatch, getState)  => {
         
        const orgId  = getState().agentProfile.orgId;
        const corrId = getState().searchForm.uuid;
        const agencyId = getState().agentProfile.agencyId;
        const flightType = getState().searchForm.flightType;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
        const agentData = [];
        
         // axios payload
        const config = {
          method: "GET",            
          redirect: "follow",
          url : env.REACT_APP_PROFILE_ORG_BASE_URL + `/users/external/autoSuggestV2?orgId=${orgId}&name=${value}&travelBusinessesId=${agencyId}`,
          headers: {
              "Content-type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              
              correlationId: corrId,
              flightType:flightType,
              bookingChannel:bookingChannel 
          },
        }
         
        const result =  await getApiResponse(config); 
        result && Array.isArray(result) && result.filter((val) => {
          if(value === ""){
            return val
          }else if(val.searchUsername.toLowerCase().includes(value.toLocaleLowerCase())){
            return val
          }
        })

        result && Array.isArray(result.Items) && result.Items.map((data)=> { 
          console.log("data",data)
            data['seacrhVariable'] = data.firstName + " " + data.lastName  +  " (" + data.username + ") "
            agentData.push({value: data, label: data['seacrhVariable'] });
        })

        if(result?.message === "No records found."){
           dispatch((setAgentList([])));
        } else {
           dispatch((setAgentList(agentData)));
        }
        return agentData


    }
}