import React, { useState } from "react";
import bags from "../../Assets/bag-icon.svg";
import seats from "../../Assets/seat-icon.svg";
import carryOnBag from "../../Assets/carry-on-icon.svg";
import BookingSubHead from "./BookingSubHead";
import { useSelector } from "react-redux";
import moment from "moment";
import { timeConvert } from "../../utils/utility/convertmintohours";
import { useDispatch } from "react-redux";
import { getPenaltiesData } from "../../appStore/thunks/getPenaltiesData";
import { getPenaltiesDataForPNR } from '../../appStore/thunks/getPenaltiesDataForPNR';
import DetailsTab from "../../components/searchResult/DetailsTab";
import { BookingFrom, BookingStatusCode, FlightLogo, TripType } from "../../utils/constant";
import { diffrenceInDatesCheck, getFlightLogo,splitFromCapital } from "../../utils/utility/commenFunctions";
import { OverlayTrigger } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import { useTranslation } from "react-i18next";
import { getPenaltiesDataForQuote } from "../../appStore/thunks/getPenaltiesDataForQuote";
import { getRevisedFareRules } from "../../appStore/thunks/getRevisedFareRules";
import DuffleClassOfServicePopUp from "../common/duffleClassOfServicePopUp";

// function sleep(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

function BookingItineraryOneway(props) {
  const dispatch = useDispatch();
  const [flightDIndex, setFlightDIndex] = useState(null);

  const [HideFlight, setHideFlight] = useState(false);
  const [duffleCarrier,setDuffleCarrier] = useState('AC');
  const itinenaryData = props.itinenary;
  const [duffleClassOfServicePopUp, setDuffleClassOfServicePopUp] = useState(false);
  const tripTypeValue = useSelector((state) => state.searchForm.tripType);
  const cabinValue = useSelector((state) => state.searchForm.cabin);
  const bookingWithQuote = useSelector((state) => state.savedQuotes.bookingWithQuote);
  const lang = useSelector( (state) => state.lang.lang)
  let adultsCount 
  let childCount 
  let infantCount

  const searchAdultCOunt = useSelector((state) => state.searchForm.passengerAdult);
  const searchChildCount = useSelector((state) => state.searchForm.passengerChildren);
  const searchInfantCount = useSelector((state) => state.searchForm.passengerInfant);
  const pnrAdultsCount = useSelector((state) => state.flightBooking.pnrAdultCount)
  const pnrChildCount = useSelector((state) => state.flightBooking.pnrChildCount)
  const pnrInfantCount = useSelector((state) => state.flightBooking.pnrInfantCount)
  const pnrFlag = useSelector((state) => state.flightBooking.pnrFlag)
  const bookingItineriesData = useSelector((state) => state.flightBooking.bookingItenaries);
  const isDuffleFlow = useSelector((state)=> state.itinenaries.providerType);
  const quoteDetails = useSelector((state) => state.savedQuotes.quoteDetails);
  const saveQuotesPaxFares = quoteDetails && quoteDetails.fareDetails && quoteDetails.fareDetails.itineraryFare && quoteDetails.fareDetails.itineraryFare.paxFares;
  const paxFares = bookingWithQuote ? saveQuotesPaxFares : bookingItineriesData && bookingItineriesData.paxFares
  const fareBasisDetails =  paxFares && paxFares[0] && paxFares[0].fareBasisDetails
  const bookingDetails = useSelector((state) => state.flightBooking.pnrCancellationStatus);
  const bookingItinenariesInitial = useSelector((state) => state.searchForm.bookingItinenary);
  let bookingItinenaries;
  if(props.from === BookingFrom.CONFIRMATION){
    bookingItinenaries = bookingItinenariesInitial?.segments;
  }else{
    bookingItinenaries = bookingItinenariesInitial;
  }
  let pnrStatus = bookingDetails && bookingDetails.bookingStatusDetail && bookingDetails.bookingStatusDetail.code

  if (pnrFlag) {
    adultsCount = pnrAdultsCount
    childCount = pnrChildCount
    infantCount = pnrInfantCount
  } else {
    adultsCount = searchAdultCOunt
    childCount = searchChildCount
    infantCount = searchInfantCount
  }
  
  const brandCode = useSelector((state) => state.fareOptions.brandCode)
  const newPaxFares=brandCode?.paxFares;
  const flyingFromCity = useSelector(
    (state) => state.searchForm.flyingFromCity
  );
  const flyingToCity = useSelector((state) => state.searchForm.flyingToCity);
  const flyingFrom = useSelector((state) => state.searchForm.flyingFrom);
  const flyingTo = useSelector((state) => state.searchForm.flyingTo);

  

  var flightIds = [];
  var flightsName = [];
  var baggageData = [];
  let cabin;
  let baggage;
  let baggageInWeight;
  let totalSeat;
  let fareSegment =[]
  // let date = bookingHeader.ticketingTimeLimit;
  let fareId = props.fareId;
  let carryOnWeight;
  let carryOnQuantity;
  let baggageUnit;
  


  if (itinenaryData && itinenaryData.hasOwnProperty("segments") ) {

    fareSegment = itinenaryData.segments 
  }else{
    fareSegment = itinenaryData
    
  }
   
  const detailsTab = fareSegment && fareSegment[0] && Object.values(fareSegment[0]);
  const layover = fareSegment && fareSegment[0] && Object.values(fareSegment[0]) && Object.values(fareSegment[0])[0]?.layovers;
  

  const handleFlightDToggle = (i) => {
    setHideFlight(!HideFlight);
    
    if (flightDIndex == i) {
      setFlightDIndex(null);
    }
    setFlightDIndex(i);
    if(props.fareId) {
      if(bookingWithQuote){
        dispatch(getPenaltiesDataForQuote(props.fareId));
      }else{
        // dispatch(getPenaltiesData(props.fareId));
        dispatch(getRevisedFareRules(props.fareId));
      }
    }
    else if(props.recordLocator) {
      dispatch(getPenaltiesDataForPNR(props.recordLocator))
    }
  };
  let cancelledFlightCode = ["HX","UC","UN","NO","WK"]

  var localeData = moment.updateLocale('fr', {
    monthsShort: [
      "Jan", "Fév", "Mars", "Avr", "Mai",
      "Juin", "Juil", "Août", "Sept", "Oct", "Nov", "Déc"
    ],
    weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
  });
  var Frmonts = localeData.monthsShort()
  var Frdays = localeData.weekdaysShort();
  if (lang == "en") {
    moment.locale('en')
  }
  if (lang == "fr") {
    moment.locale('fr', Frmonts);
    moment.locale('fr', Frdays);
  }

  function findBaggages(flightData, paxFares) {
    const [{ fareBasisDetails = [] }] = paxFares;
    const findFlightBaggage = fareBasisDetails.find(flight => flight.flightId === flightData.id)
    return findFlightBaggage;
  }
  
  const { t } = useTranslation();
  return (
    <>
      {(props.fromBookingConfirmation || props.from === BookingFrom.CONFIRMATION) ? (
        <div className="bd-itinerary-box">
          {/* {!props.isCalcultor &&  <BookingItinerayInfo bookingHeader={bookingHeader} from={props.from}/>} */}

          <div className="bd-itinery-detail">
            <div className="col-12 bd-itinery-box-wrapper">
              <div className="bd-itinery-box">
                {fareSegment && fareSegment.map((data, index) => {
                  let layoverTime = 0;
                  let flightsArray = Object.values(data)[0].flights;
                  cabin = Object.values(data)[0].cabin;
                  totalSeat = Object.values(data)[0].seatsRemaining;
                  
                  return (
                    <div key={index}>
                      <div className="bd-itinery-box-head">
                        <div>
                          <strong>{t("Outbound")}</strong>
                          {flyingFromCity} ({flyingFrom}) - {flyingToCity} (
                          {flyingTo}) {cabin && " | " + t(splitFromCapital(cabin))}
                        </div>
                        <div className="bd-ib-total">
                          {layover && layover.map((value) => {
                            layoverTime += value.durationMinutes;
                          })}
                          {Object.values(data)[0].durationMinutes !== undefined && <>  
                            {t("Total Duration:")}
                          {timeConvert(
                            Object.values(data)[0].durationMinutes
                          )}
                          </>}
                        </div>
                      </div>
                      {flightsArray.map((flight, index) => {

                        let arrivalDate = flight.arrivalDateTime
                        let dapartureDate = flight.departureDateTime
                        let diffrenceInDates = diffrenceInDatesCheck(dapartureDate,arrivalDate);

                        flightIds.push(flight.id);

                        let intermediateBaggageData = []
                        paxFares?.forEach(element => {
                          let fareBasisDetails = element.fareBasisDetails;
                          fareBasisDetails.forEach((elem) => {
                            if (elem.flightId == flight.id) {
                              let obj = {
                                chargedBaggageDetails: elem.chargedBaggage,
                                freeBaggageDetails: [elem.freeBaggage],
                                paxType: element.passengerDetails.type,
                                carryOnBaggageDetails: elem?.carryOnBaggage,
                                carryOnBaggageDetails: elem?.carryOnBaggage
                              }
                              intermediateBaggageData.push(obj)
                            }
                          })

                        });
                        baggageData.push(intermediateBaggageData);
                        var name =
                          flight.departureAirport + "-" + flight.arrivalAirport;
                        flightsName.push(name);


                        let carrierCode = flight && flight.carrier
                        let flightLogo = getFlightLogo(carrierCode)

                        let flightClassOfservice = fareBasisDetails && fareBasisDetails.filter(obj=>obj.flightId===flight.id)
                        let classOfService = flightClassOfservice && flightClassOfservice?.[0] && flightClassOfservice?.[0].classOfService
                        let flightStatusCode = flight?.statusDetail?.code;

                        const baggageInfo = paxFares && findBaggages(flightsArray[0], paxFares)
                        baggageInWeight = baggageInfo?.freeBaggage?.weight;
                        baggage = baggageInfo?.freeBaggage && baggageInfo.freeBaggage?.quantity;
                        carryOnWeight = baggageInfo?.carryOnBaggage?.[0]?.weight;
                        carryOnQuantity = baggageInfo?.carryOnBaggage?.[0] && baggageInfo?.carryOnBaggage[0]?.quantity;
                        baggageUnit = baggageInfo?.freeBaggage?.unit;

                        return (
                          <div key={flight.id}>
                            <div className="bd-itinery-item" key={index}>
                            {flight && flight.statusDetail && flight.statusDetail.code === "UN" ?
                                <div className="title">
                                  <img src={flightLogo} alt="flightLogo" />
                                  {flight.carrier + " " + flight.number +" " + (classOfService?classOfService:"")}
                                </div>
                                :
                                <div className="title">
                                   <img src={flightLogo} alt="flightLogo" />
                                   <OverlayTrigger
                                trigger="hover"
                                placement="top"
                                delay={{ show: 100 }}
                                overlay={
                                <Popover className="seatView-popover">
                                    <div className="seatView-popover-row seatView-popover-amount">                      
                                    <span className="airPort_name">{flight?.carrierName ? flight?.carrierName : flight?.carrierName}</span>
                                  </div>
                                </Popover>
                                }
                            >                              
                            <span className="bd-carrier-Name">{flight.carrier}</span>
                                
                          </OverlayTrigger>
                            {flight.number} &nbsp; {props.from == 'confirmation' && isDuffleFlow ? <i className="fas fa-info-circle" style={{"color" : "#0055B8"}} onClick={()=>(setDuffleClassOfServicePopUp(true),setDuffleCarrier(flight.carrier))}></i> : classOfService}
                                 </div>
                            }

                              <div className="flight-from-to">
                                <div className="flight-from-to-city">
                                  <div className="city">
                                    <strong>{flight.departureAirport}</strong>{" "}
                                    {moment.utc(flight.departureDateTime).format(
                                      "HH:mm"
                                    )}
                                  </div>
                                  <span className="date">
                                    {moment.utc(flight.departureDateTime).format(
                                      "ddd MMM DD, YYYY"
                                    )}
                                  </span>
                                </div>
                                <div className="flight-from-to-divider"></div>
                                <div className="flight-from-to-city">
                                  <div className="city">
                                    <strong>{flight.arrivalAirport}</strong>
                                    {diffrenceInDates !== 0 ? <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")} <sup className="sid-change-days">{Number(diffrenceInDates) > 0 ? "+" + diffrenceInDates : diffrenceInDates}</sup> </span> : <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")}</span>}
                                    {/* {moment.utc(flight.arrivalDateTime).format(
                                      "HH:mm"
                                    )} */}
                                  </div>
                                  <span className="date">
                                    {moment.utc(flight.arrivalDateTime).format(
                                      "ddd MMM DD, YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                              {(pnrStatus === BookingStatusCode.NTC || pnrStatus === BookingStatusCode.TC || pnrStatus === BookingStatusCode.PNTC || cancelledFlightCode.includes(flightStatusCode))? 

                                <div className="time-from-to">
                                  <span className="tktonholdFailed">{t("Cancelled")}</span>
                                </div>
                                :
                                <div className="time-from-to">
                                {timeConvert(flight.durationMin)}
                                </div>
                              }

                             
                            </div>
                            {layover && layover[index] ? (
                              <div className="bd-itinery-layover">
                                <span>
                                  {timeConvert(layover[index].durationMinutes) +
                                    t("Layover in") + flight.arrivalAirport}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}

                <div className="bd-itinery-btns">
                  <div className="seatBag">
                    {totalSeat && <span>
                      <img src={seats} alt="seat" />
                      {totalSeat}
                    </span>}

                    {(baggageInWeight || baggage) &&
                      <span>
                        <img src={bags} alt="seat" />
                        {baggageInWeight > baggage ? " " + baggageInWeight + ` ${baggageUnit}` : baggage}
                      </span>
                    }
                    {(carryOnWeight || carryOnQuantity) &&
                      <span>
                        <img src={carryOnBag} className="carryOnSeatsBooking" alt="carryOnBag" />
                        {(carryOnQuantity == undefined || carryOnWeight > carryOnQuantity) ? " " + carryOnWeight + " Kg" : carryOnQuantity}
                      </span>
                    }
                  </div>
                  {pnrStatus !== BookingStatusCode.NTC && pnrStatus !== BookingStatusCode.TC && pnrStatus !== BookingStatusCode.PNTC &&
                  <div>
                    <button
                      onClick={() => {
                        handleFlightDToggle(1);
                      }}
                    >
                      {t("Flight Details")}
                    </button>
                  </div>
                  }
                </div>

                <div className={HideFlight ? "" : "flight_tabs_none"}>
                  <DetailsTab
                    flightdata={detailsTab}
                    baggagefares={baggageData}
                    flightName={flightsName}
                    fareId={fareId}
                    recordLocator={props.recordLocator}
                    paxFare={paxFares}
                    baggage={bookingItinenaries && bookingItinenaries[0]&&Object.values(bookingItinenaries?.[0])?.[0]}
                    isDuffleFlow={isDuffleFlow}
                    flowFrom={props.from}
                    finalCabinName={cabin}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bd-itinerary-sec">
          <BookingSubHead
            title={t("Itinerary")}
          />
          <div className="one_line">
            <ul className="bd-trip-info">
              <li>{tripTypeValue === TripType.ONEWAY && t("OneWay")}</li>
              <li>{cabinValue && t(splitFromCapital(cabinValue))} {t("Class")}</li>
              <li>
                {adultsCount} {t("Adult")} {childCount} {t("Child")} {infantCount} {t("Infant")}
              </li>

            </ul>
            <p>{brandCode.brandName} </p>
          </div>

          <div className="bd-itinerary-box">
            {/* <BookingItinerayInfo bookingHeader={bookingHeader} /> */}
            <div className="bd-itinery-detail">
              <div className="col-12 bd-itinery-box-wrapper">
                <div className="bd-itinery-box">
                  {fareSegment && fareSegment.map((data, index) => {
                    let layoverTime = 0;
                    let flightsArray = Object.values(data)[0].flights;
                    cabin = Object.values(data)[0].cabin;
                    baggage = Object.values(data)[0].baggage;
                    baggageInWeight = Object.values(data)[0].weight;
                    totalSeat = Object.values(data)[0].seatsRemaining;
                    let classOfService1 =  Object.values(data)[0].classOfService 
                    baggageUnit= Object.values(data)[0].unit;
                    if(bookingWithQuote){
                      classOfService1 = fareBasisDetails
                    }       
                    return (
                      <div key={index}>
                        <div className="bd-itinery-box-head">
                          <div>
                            <strong>{t("Outbound")}</strong>
                            {flyingFromCity} ({flyingFrom}) - {flyingToCity} (
                            {flyingTo})  {cabin && " | " + t(splitFromCapital(cabin))}
                          </div>
                          <div className="bd-ib-total">
                            {layover && layover.map((value) => {
                              layoverTime += value.durationMinutes;
                            })}
                            {t("Total Duration:")}
                            {timeConvert(
                              Object.values(data)[0].durationMinutes

                            )}
                          </div>
                        </div>
                        {flightsArray.map((flight, index) => {

                          let arrivalDate = flight.arrivalDateTime
                          let dapartureDate = flight.departureDateTime
                          let diffrenceInDates = diffrenceInDatesCheck(dapartureDate,arrivalDate);

                          flightIds.push(flight.id);
                          // baggageData.push(flight.baggageDetails);
                          var name =
                            flight.departureAirport +
                            "-" +
                            flight.arrivalAirport;
                          flightsName.push(name);


                          let carrierCode = flight && flight.carrier
                          let flightLogo = getFlightLogo(carrierCode)

                          let flightClassOfservice = classOfService1 && classOfService1.filter(obj=>obj.flightId===flight.id)
                          let classOfService = flight.classOfService ? flight.classOfService : flightClassOfservice && flightClassOfservice[0] && flightClassOfservice[0].classOfService
                          let finalPaxFares = newPaxFares ? newPaxFares : paxFares;
                          const baggageInfo = finalPaxFares && findBaggages(flightsArray[0], finalPaxFares)
                          baggageInWeight = baggageInfo?.freeBaggage?.[0]?.weight;
                          baggage = baggageInfo?.freeBaggage?.[0] && baggageInfo.freeBaggage?.[0]?.quantity;
                          carryOnWeight = baggageInfo?.carryOnBaggage?.[0]?.weight;
                          carryOnQuantity = baggageInfo?.carryOnBaggage?.[0] && baggageInfo?.carryOnBaggage[0]?.quantity;
                          baggageUnit = baggageInfo?.freeBaggage?.[0]?.unit;
                          let intermediateBaggageData = []
                          finalPaxFares?.forEach(element => {
                            let fareBasisDetails = element.fareBasisDetails;
                            fareBasisDetails.forEach((elem) => {
                              if (elem.flightId == flight.id) {
                                let obj = {
                                  chargedBaggageDetails: elem.chargedBaggage,
                                  freeBaggageDetails: elem.freeBaggage,
                                  paxType: element.passengerDetails.type,
                                  carryOnBaggageDetails: elem?.carryOnBaggage
                                }
                                intermediateBaggageData.push(obj)
                                classOfService = elem?.classOfService;
                              }
                            })

                          });
                          baggageData.push(intermediateBaggageData);
                          
                          return (
                            <div key={flight.id}>
                              <div className="bd-itinery-item">
                                <div className="title">
                                  <img src={flightLogo} alt="flightLogo" />
                                  <OverlayTrigger
                                trigger="hover"
                                placement="top"
                                delay={{ show: 100 }}
                                overlay={
                                <Popover className="seatView-popover">
                                    <div className="seatView-popover-row seatView-popover-amount">                      
                                    <span className="airPort_name">{flight?.carrierName ? flight?.carrierName : flight?.carrierName}</span>
                                  </div>
                                </Popover>
                                }
                            >                              
                                <span className="bd-carrier-Name">{flight.carrier}</span>
                                
                        </OverlayTrigger>

                       {flight.number} &nbsp; {props.from == 'confirmation' && isDuffleFlow ? <i className="fas fa-info-circle" style={{"color" : "#0055B8"}} onClick={()=>(setDuffleClassOfServicePopUp(true),setDuffleCarrier(flight.carrier))}></i> : classOfService}
                                </div>

                                <div className="flight-from-to">
                                  <div className="flight-from-to-city">
                                    <div className="city">
                                      <strong>{flight.departureAirport}</strong>{" "}
                                      {moment.utc(flight.departureDateTime).format(
                                        "HH:mm"
                                      )}
                                    </div>
                                    <span className="date">
                                      {moment.utc(flight.departureDateTime).format(
                                        "ddd MMM DD, YYYY"
                                      )}
                                    </span>
                                  </div>
                                  <div className="flight-from-to-divider"></div>
                                  <div className="flight-from-to-city">
                                    <div className="city">
                                      <strong>{flight.arrivalAirport}</strong>
                                      {diffrenceInDates !== 0 ? <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")} <sup className="sid-change-days">{Number(diffrenceInDates) > 0 ? "+" + diffrenceInDates : diffrenceInDates}</sup> </span> : <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")}</span>}
                                      {/* {moment.utc(flight.arrivalDateTime).format(
                                        "HH:mm"
                                      )} */}
                                    </div>
                                    <span className="date">
                                      {moment.utc(flight.arrivalDateTime).format(
                                        "ddd MMM DD, YYYY"
                                      )}
                                    </span>
                                  </div>
                                </div>

                                <div className="time-from-to">
                                  {timeConvert(flight.durationMin)}
                                </div>
                              </div>
                              {layover && layover[index] ? (
                                <div className="bd-itinery-layover">
                                  <span>
                                    {timeConvert(
                                      layover[index].durationMinutes
                                    ) + t("Layover in") + flight.arrivalAirport}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}

                  <div className="bd-itinery-btns">
                    <div className="seatBag">
                      <span>
                        <img src={seats} alt="seat" />
                        {totalSeat}
                      </span>
                      {(baggageInWeight || baggage) &&
                        <span>
                        <img src={bags} alt="seat" />
                        {(baggage == undefined || baggageInWeight > baggage) ? " " + baggageInWeight + ` ${baggageUnit}` : baggage}
                      </span>}
                        {(carryOnWeight || carryOnQuantity) &&
                          <span>
                            <img src={carryOnBag} className="carryOnSeatsBooking" alt="carryOnBag" />
                            {(carryOnQuantity == undefined || carryOnWeight > carryOnQuantity) ? " " + carryOnWeight + " Kg" : carryOnQuantity}
                          </span>
                        }
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          handleFlightDToggle(1);
                        }}
                      >
                        {t("Flight Details")}
                      </button>
                    </div>
                  </div>

                  <div className={HideFlight ? "" : "flight_tabs_none"}>
                    <DetailsTab
                      flightdata={detailsTab}
                      baggagefares={baggageData}
                      flightName={flightsName}
                      fareId={props.fareId}
                      recordLocator={props.recordLocator}
                      paxFare={paxFares}
                      baggage={bookingItinenaries && bookingItinenaries[0]&&Object.values(bookingItinenaries?.[0])?.[0]}
                      isDuffleFlow={isDuffleFlow}
                      flowFrom={props.from}
                      finalCabinName={cabin}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <DuffleClassOfServicePopUp open={duffleClassOfServicePopUp} handleClose={()=>setDuffleClassOfServicePopUp(false)} carrier={duffleCarrier}/>
    </>
  );
}

export default BookingItineraryOneway;
