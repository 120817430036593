import { useDispatch } from 'react-redux';
import { setAirlines,setAirports,setNoOfConnections, setFilterBody, setPriceRange, setConnectionAirports, setTripDurations, setConnectionDurations,
    setDepartureTimes, setArrivalTimes, setAlliances, setBooleanFilters, setfareTypes, sethideChagesofAirport, sethideNearbyAirport, setFilterSearchResult,
    setFilterLoading, setFilterResetItinenaryData, setFilterResetItinenaryRowsKeys, setFilterResetDetailedItinenaryRows, setFilterResetInitialData,
    setFilterActiveData, setConnectionsActiveStatus, setConnectionAirportActiveStatus, setTripDurationActiveStatus, setDepartureTimeActiveStatus,
    setArrivalTimeActiveStatus, setAirlinesActiveStatus, setAlliancesActiveStatus, setPriceRangeActiveStatus, setFreeBaggageActiveStatus, 
    setConnectionDurationActiveStatus, setHideChangesActiveStatus, setFilterNoResultsStatus, setAirlinesCarrierData, setairAlliancesCarrierData,
    setPriceRangeData, setConnectionFilterData, setConnectionAirportData, setConnectionDurationData, setTripDurationData, setFreeBaggageFilter, setAppliedFilterRecord,setDepartureTimesFilterData,
    setArrivalTimesFilterData,setHideChangesfilter, setFlightNumbersStatus, setFlightNumbersInStore
 } from '../actionCreators/Filter/filter_actionCreators';

export function ResetFilter() {
    const dispatch = useDispatch();

    dispatch(setAirlines([]));
    dispatch(setFilterLoading(true));
    dispatch(setAppliedFilterRecord([]));
    dispatch(setairAlliancesCarrierData([]));
    dispatch(setFreeBaggageFilter(""));
    dispatch(setTripDurationData({}));
    dispatch(setConnectionAirportData([]));
    dispatch(setConnectionDurationData([]));
    dispatch(setPriceRangeData({}));
    dispatch(setConnectionFilterData({}));
    dispatch(setConnectionDurationActiveStatus(false));
    dispatch(setFilterNoResultsStatus(false));
    dispatch(setAirlinesCarrierData([]));
    dispatch(setHideChangesActiveStatus(false));
    dispatch(setTripDurationActiveStatus(false));
    dispatch(setFreeBaggageActiveStatus(false));
    dispatch(setPriceRangeActiveStatus(false));
    dispatch(setAlliancesActiveStatus(false));
    dispatch(setAirlinesActiveStatus(false));
    dispatch(setArrivalTimeActiveStatus(false));
    dispatch(setDepartureTimeActiveStatus(false));
    dispatch(setConnectionsActiveStatus(false));
    dispatch(setConnectionAirportActiveStatus(false));
    dispatch(setFilterActiveData([]));
    dispatch(setFilterResetInitialData(""));
    dispatch(setFilterResetItinenaryRowsKeys([]));
    dispatch(setFilterResetDetailedItinenaryRows([]));
    dispatch(setFilterResetItinenaryData({}));
    dispatch(sethideNearbyAirport(""));
    dispatch(setFilterSearchResult([]));
    dispatch(setAlliances([]));
    dispatch(sethideChagesofAirport(""));
    dispatch(setfareTypes([]));
    dispatch(setBooleanFilters([]));
    dispatch(setTripDurations([]));
    dispatch(setDepartureTimes([]));
    dispatch(setArrivalTimes([]));
    dispatch(setConnectionDurations([]));
    dispatch(setPriceRange({}));
    dispatch(setConnectionAirports([]));
    dispatch(setAirports([]));
    dispatch(setNoOfConnections([]));
    dispatch(setFilterBody({}));
    dispatch(setDepartureTimesFilterData({}))
    dispatch(setArrivalTimesFilterData({}))
    dispatch(setHideChangesfilter(false))
    dispatch(setFlightNumbersStatus(false));
    dispatch(setFlightNumbersInStore([]));
}