import { Tabs, Tab } from "react-bootstrap";
import { TripType, Penalties } from "../../utils/constant";

import { useSelector } from "react-redux";
import React, { useState } from "react"
import PenaltyTabData from "./penaltyTabData"
import { useTranslation } from "react-i18next";
import PenaltyTabDataMulticity from "./PenaltiesTabDataMulticity";


function PenaltiesTab(props) {
  const fareId = props.fareId;
  const recordLocator = props.recordLocator;
  const flowFrom = props.flowFrom

  const freeTextFareRules1 = useSelector((state) => state.Penalaties.freeTextFareRules);
  const structuredFareRules1 = useSelector((state) => state.Penalaties.structuredFareRules);
  const tripTypeValue = useSelector((state) => state.searchForm.tripType);
  let i = 0;
  let j = 0;
  let structuredFareRules;
  let freeTextFareRules;

  if (props.from) {
    if (props.from === "left") {
      let newReult = structuredFareRules1 && structuredFareRules1.filter(data => data[fareId])
      if (newReult) {
        structuredFareRules = newReult[0] && newReult[0][fareId];

      }

    }
    if (props.from === "right") {
      let newReult = structuredFareRules1 && structuredFareRules1.filter(data => data[fareId])
      if (newReult) {
        structuredFareRules = newReult[0] && newReult[0][fareId];
      }


    }
  } else {
    if (structuredFareRules1) {
      for (i = 0; i < structuredFareRules1.length; i++) {
        structuredFareRules = structuredFareRules1[i][fareId];
        if (structuredFareRules) break;
      }
    }
  }

  if (props.from && fareId) {
    if (props.from === "left") {
      let newReult = freeTextFareRules1.filter(data => data[fareId])
      if (newReult) {
        freeTextFareRules = newReult[0] && newReult[0][fareId];
      }

    }
    if (props.from === "right") {
      let newReult = freeTextFareRules1.filter(data => data[fareId])
      if (newReult) {
        freeTextFareRules = newReult[0] && newReult[0][fareId];
      }


    }
  } else if (fareId) {
    if (freeTextFareRules1) {
      for (j = 0; j < freeTextFareRules1.length; j++) {
        freeTextFareRules = freeTextFareRules1[j][fareId];
        if (freeTextFareRules) break;
      }
    }
  }

 if (recordLocator) {
    if (freeTextFareRules1) {
      for (j = 0; j < freeTextFareRules1.length; j++) {
        freeTextFareRules = freeTextFareRules1[j][recordLocator];
        if (freeTextFareRules) break;
      }
    }

  }
 
  let arr = []

  if (freeTextFareRules === Penalties.STR_FARE_RULES_ERROR) {

    arr.push({ error: freeTextFareRules })

  }
  let leftData
  let rightData
  let fareRuleDetails
  if(freeTextFareRules !== Penalties.STR_FARE_RULES_ERROR){
    leftData = freeTextFareRules && freeTextFareRules?.filter(fareRule => fareRule.fareDirection === "OUTBOUND");
    rightData = freeTextFareRules && freeTextFareRules?.filter(fareRule => fareRule.fareDirection === "INBOUND");
    fareRuleDetails = freeTextFareRules
  }
  // else{
  //   leftData = TripType.ROUNDTRIP && freeTextFareRules && freeTextFareRules.segments ? (freeTextFareRules.segments[0] && (freeTextFareRules.segments[0].fareRules ? freeTextFareRules.segments[0].fareRules : [])) : arr   
  //   rightData = TripType.ROUNDTRIP && freeTextFareRules && freeTextFareRules.segments ? (freeTextFareRules.segments[1] && freeTextFareRules.segments[1].fareRules ? freeTextFareRules.segments[1].fareRules : freeTextFareRules.segments[0] && freeTextFareRules.segments[0].fareRules ? freeTextFareRules.segments[0].fareRules : []) : arr
  //   fareRuleDetails = freeTextFareRules &&
  //     (freeTextFareRules.fareRules ?
  //       freeTextFareRules.fareRules : []);
  // }

  var exchangeArray = [];
  var refundArray = [];
  var flightNamesArray = [];
  var flightDetails;
  let departureAirportCode;
  let arrivalAirportCode;
  let carrier;


  if (tripTypeValue !== TripType.MULTICITY) {
    flightDetails =
      props.flightDetails &&
      Object.values(props.flightDetails)[0] &&
      Object.values(props.flightDetails)[0].flights;


    departureAirportCode = flightDetails && flightDetails[0].departureAirport
    arrivalAirportCode = flightDetails && flightDetails[flightDetails.length - 1].arrivalAirport
    carrier = flightDetails && flightDetails[0].carrier

    flightDetails &&
      flightDetails.map((flight) => {
        flightNamesArray.push({
          flightName: flight.departureAirport + "-" + flight.arrivalAirport,
          carrier: flight.carrier,
          flightNumber: flight.number,
        });
      });
  }

  if (tripTypeValue === TripType.MULTICITY) {
    if (props.flightDetails) {

      props.flightDetails.map((flight) => {
        let flights = flight && Object.values(flight)[0].flights;
        let departureAirport = flights && flights[0].departureAirport
        let arrivalAirport = flights && flights[flights.length - 1].arrivalAirport
        let carrier = flights && flights[0].carrier

        flightNamesArray.push({
          flightName: departureAirport + "-" + arrivalAirport,
          carrier: carrier,
        });

      })
    }
    
  }

  let flightSementArr = []
  let duplicateFlightCheck =[]
  if (tripTypeValue === TripType.MULTICITY) {
    freeTextFareRules && freeTextFareRules.forEach(flightSegment => {
     let flightDirection = flightSegment?.segmentDirection
     if(!duplicateFlightCheck.includes(flightDirection)){
     duplicateFlightCheck.push(flightDirection)
     flightSementArr.push({
      segmentDirection:flightDirection,
      fareBasisCode:flightSegment.fareBasisCode
     })
    }
    })
  }


  if (structuredFareRules !== "FareRulesError")
    structuredFareRules &&
      structuredFareRules[0] &&
      structuredFareRules[0]?.penalties?.map((value) => {
        if (value.type === "Exchange") {
          exchangeArray.push({
            exchange: value.type,
            amount: value.amount,
            applicability: value.applicability,
            changeability: value.changeability,
            currency: value.currency
          });
        }

        if (value.type === "Refund") {
          refundArray.push({
            refundType: value.type,
            amount: value.amount,
            applicability: value.applicability,
            refundability: value.refundability,
            currency: value.currency
          });
        }
      });

      const { t } = useTranslation();
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Tabs
              defaultActiveKey="RULES"
              id="fareRulesTabs"
              className="fareRulesTabs"
            >

              <Tab eventKey="RULES" title={t("Rules")} className="tabs  b-0">

                <div className="pena_rulsWrapper">
                  {tripTypeValue === TripType.MULTICITY ?
                                 <>
                                 {flowFrom === "search" ?
                                <div>
                                  {freeTextFareRules === undefined && <div className="message_font">
                                    {t('1005')}
                                  </div>}
                                  {flightSementArr &&
                                    flightSementArr.map((rule) => {
                                      
                                      let fareBasisCode = rule.fareBasisCode;
                                      let segmentDirection = rule?.segmentDirection

                                      return (
                                        <>
                                          <div className="fd-baggage-flight">
                                            {segmentDirection}
                                          </div>
                                          <PenaltyTabDataMulticity leftData={fareRuleDetails} fareId={fareId} flowFrom="outBound" fareBasisCode={fareBasisCode} segmentDirection={segmentDirection}/>
            
                                        </>
                                      );
                                    })}
                                </div>
                                :
                                <PenaltyTabData leftData={fareRuleDetails} fareId={fareId} flowFrom="outBound"/>
            
                                  }
                                 </>                     
                    :
                    <>
                      {(tripTypeValue === TripType.ROUNDTRIP || tripTypeValue === TripType.OPENJAW) &&
                        <>
                          {props.from === "left" &&
                            <PenaltyTabData leftData={leftData} fareId={fareId} flowFrom="outBound"/>

                          }
                          {props.from === "right" &&
                            <div>
                              <PenaltyTabData leftData={rightData} fareId={fareId} flowFrom="inBound"/>
                            </div>
                          }

                        </>

                      }

                      {/* for oneWay */}
                      {(tripTypeValue === TripType.ONEWAY) &&
                        <div>
                          <PenaltyTabData leftData={fareRuleDetails} fareId={fareId} flowFrom="outBound"/>
                        </div>

                      }
                      {/* end of for OneWAY */}
                    </>
                  }
                </div>

              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default PenaltiesTab;
