export const SET_TOW_AIRLINES = "SET_TOW_AIRLINES";  

export const SET_TOW_AIRPORTS = "SET_TOW_AIRPORTS";

export const SET_TOW_NO_OF_CONNECTIONS = "SET_TOW_NO_OF_CONNECTIONS";

export const SET_TOW_PRICE_RANGE = "SET_TOW_PRICE_RANGE";

export const SET_TOW_CONNECTION_AIRPORTS = "SET_TOW_CONNECTION_AIRPORTS";

export const SET_TOW_TRIP_DURATIONS = "SET_TOW_TRIP_DURATIONS";

export const SET_TOW_CONNECTION_DURATIONS = "SET_TOW_CONNECTION_DURATIONS";

export const SET_TOW_DEPARTURE_TIMES = "SET_TOW_DEPARTURE_TIMES";

export const SET_TOW_ARRIVAL_TIMES = "SET_TOW_ARRIVAL_TIMES";

export const SET_TOW_ALLIANCES = "SET_TOW_ALLIANCES";

export const SET_TOW_BOOLEANFILTERS =  "SET_TOW_BOOLEANFILTERS";

export const SET_TOW_FARETYPES =  "SET_TOW_FARETYPES";

export const SET_TOW_FARE_WITH_FREE_BAG_ALLOWANCE = "SET_TOW_FARE_WITH_FREE_BAG_ALLOWANCE";

export const SET_TOW_FARE_WITH_FREE_SEAT_SELECTION = "SET_TOW_FARE_WITH_FREE_SEAT_SELECTION";

export const SET_TOW_HIDE_CHANGES_OF_AIRPORT = "SET_TOW_HIDE_CHANGES_OF_AIRPORT";

export const SET_TOW_HIDE_NEAR_BY_AIRPORT = "SET_TOW_HIDE_NEAR_BY_AIRPORT";

export const SET_TOW_FILTER_SEARCH_RESULT = "setTowFilterSearchResult";

export const SET_TOW_FILTER_ISLOADING = "SET_TOW_FILTER_ISLOADING";

export const SET_TOW_FILTER_BODY = "setTowFilterBody";
export const SET_TOW_APPLIED_FILTER_FLAG = "setTowAppliedFilterFlag"
export const SET_TOW_APPLIED_FILTER_RECORD = "setTowAppliedFilterRecord"

// Filter Reset

export const SET_TOW_FILTER_RESET_ITINENARY_DATA = "setTowFilterResetItinenaryData"
export const SET_TOW_FILTER_RESET_ITINENARY_ROWS_KEYS= "setTowFilterResetItinenaryRowsKeys"
export const SET_TOW_FILTER_RESET_DETAILED_ITINENARY_ROWS= "setTowFilterResetDetailedItinenaryRows"
export const SET_TOW_FILTER_RESET_INITIAL_DATA = "setTowFilterResetInitialData"

// filter active Data Value And Count
export const SET_TOW_FILTER_ACTIVE_DATA = "setTowFilterActiveData"
export const SET_TOW_CONNECTIONS_ACTIVE_STATUS = "setTowConnectionsActiveStatus"
export const SET_TOW_CONNECTION_AIRPORT_ACTIVE_STATUS = "setTowConnectionAirportActiveStatus"
export const SET_TOW_TRIP_DURATION_ACTIVE_STATUS = "setTowTripDurationActiveStatus"
export const SET_TOW_DEPARTURE_TIME_ACTIVE_STATUS = "setTowDepartureTimeActiveStatus"
export const SET_TOW_ARRIVAL_TIME_ACTIVE_STATUS = "setTowArrivalTimeActiveStatus"
export const SET_TOW_AIRLINES_ACTIVE_STATUS = "setTowAirlinesActiveStatus"
export const SET_TOW_ALLIANCES_ACTIVE_STATUS = "setTowAlliancesActiveStatus"
export const SET_TOW_PRICE_RANGE_ACTIVE_STATUS = "setTowPriceRangeActiveStatus"
export const SET_TOW_FREE_BAGGAGE_ACTIVE_STATUS = "setTowFreeBaggageActiveStatus"
export const SET_TOW_CONNECTION_DURATION_ACTIVE_STATUS = "setTowConnectionDurationActiveStatus"
export const SET_TOW_HIDE_CHANGES_ACTIVE_STATUS = "setTowHideChangesActiveStatus"
export const SET_TOW_FILTER_N0_RESULTS_STATUS = "setTowFilterNoResultsStatus"

// FILTER VALUE FOR PREPOPULETED AVINASH
export const SET_TOW_AIRLINES_CARRIER_DATA = "setTowAirlinesCarrierData"
export const SET_TOW_AIR_ALLIINCE_DATA = "setTowairAlliancesCarrierData"
export const SET_TOW_PRICE_RANGE_FILTER = "setTowPriceRangeData"
export const SET_TOW_CONNECTION_FILTER_DATA = "setTowConnectionFilterData"
export const SET_TOW_CONNECTION_AIRPORT_FILTER = "setTowConnectionAirportData"
export const SET_TOW_CONNECTION_DURATION_FILTER = "setTowConnectionDurationData"
export const SET_TOW_TRIP_DURATION_FILTER = "setTowTripDurationData"

export const SET_TOW_FREE_BAGGAGE_FILTER = "setTowFreeBaggageFilter"

//filter actions for fare matrix 
export const SET_TOW_FILTER_STATE_CARRIERBD =  "setTowFilterStateCarrierbd"
export const SET_TOW_FILTER_STATE_SHOW_POP_FILTER = "setTowFilterStateShowPopFilter"

export const SET_TOW_DEPARTURE_TIMES_FILTER ='setTowDepartureTimesFiletr'
export const SET_TOW_ARRIVAL_TIMES_FILTER ="setTowArrivaliTimesFilter"
export const TOW_HIDE_CHANGE_FILTER ="setTowHideChangeFilter"
export const SET_TOW_FREE_BAGGAGE_DATA = "setTowFreeBaggageData"